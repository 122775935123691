import { GraphQLClient } from "graphql-request";
// import { GRAPHQL_TRANSPORT_WS_PROTOCOL } from "graphql-ws";
// import { getToken } from "./auth";
import { getSdk } from "@/gql/gen";

// const createClient = async (url: string) => {
//   return new Promise<GraphQLWebSocketClient>((resolve) => {
//     const socket = new WebSocket(url, GRAPHQL_TRANSPORT_WS_PROTOCOL);
//     const client: GraphQLWebSocketClient = new GraphQLWebSocketClient(socket, {
//       // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
//       onAcknowledged: () => Promise.resolve(resolve(client)),
//       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//       // @ts-expect-error
//       onInit: async () => {
//         const token = await getToken();
//         return { authorization: token.token };
//       },
//     });
//   });
// };

export const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080/query"
    : "https://api.stride.is/query";

// export const API_URL = "https://api.stride.is/query";

const gclient = new GraphQLClient(API_URL);
export const client = getSdk(gclient);

// export const wsclient = () =>
//   createClient(
//     process.env.NODE_ENV === "development"
//       ? "ws://localhost:8080/query"
//       : "wss://api.stride.is/query",
//   );
