import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import numbro from "numbro";
import { MetricType } from "@/gql/gen";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function simpleFloatFormatter(value: number, places?: number) {
  return numbro(value).format({
    mantissa: places ?? 2,
    optionalMantissa: true,
  });
}

export function max(arr: (number | null)[]) {
  return arr.reduce((max, v) => Math.max(max ?? 0, v ?? 0), -Infinity);
}

export function min(arr: (number | null)[]) {
  return arr.reduce((min, v) => Math.min(min ?? 0, v ?? 0), 0);
}

export function humaniseMetricType(m: MetricType) {
  switch (m) {
    case MetricType.BodyFat:
      return "Body Fat (%)";
    case MetricType.Ftp:
      return "FTP";
    case MetricType.Hrv:
      return "HRV";
    case MetricType.RestingHeartRate:
      return "Resting Heart Rate";
    case MetricType.MaxHeartRate:
      return "Max Heart Rate";
    case MetricType.Wbal:
      return "WBAL";
    case MetricType.Weight:
      return "Weight";
    case MetricType.ThresholdHeartRate:
      return "Threshold Heart Rate";
    default:
      return m;
  }
}

export function unitForMetricType(m: MetricType) {
  switch (m) {
    case MetricType.BodyFat:
      return "%";
    case MetricType.Ftp:
      return "w";
    case MetricType.Hrv:
      return "ms";
    case MetricType.RestingHeartRate:
      return "bpm";
    case MetricType.MaxHeartRate:
      return "bpm";
    case MetricType.Wbal:
      return "kj";
    case MetricType.Weight:
      return "kg";
    case MetricType.ThresholdHeartRate:
      return "bpm";
    default:
      return m;
  }
}

export function parseDuration(input: string): number {
  // Regular expression to match the input format
  const regex = /^(\d+)(?::(\d{1,2}))?(?::(\d{1,2}))?$/;
  const match = input.trim().match(regex);

  if (!match) {
    throw new Error("Invalid duration format");
  }

  const [, first, second = "0", third = "0"] = match;

  let hours: number, minutes: number, seconds: number;

  if (second === "0" && third === "0") {
    // If only one number is provided, interpret it as minutes
    hours = 0;
    minutes = 0;
    seconds = 0;

    const input = parseInt(first, 10);
    if (input < 10) {
      hours = input;
    }

    if (input >= 10 && input < 60) {
      minutes = input;
    }
  } else {
    // Otherwise, interpret as hours:minutes:seconds
    hours = parseInt(first, 10);
    minutes = parseInt(second, 10);
    seconds = parseInt(third, 10);
  }

  // Validate the input
  if (minutes >= 60 || seconds >= 60) {
    throw new Error("Minutes and seconds must be less than 60");
  }

  // Convert everything to seconds
  return hours * 3600 + minutes * 60 + seconds;
}
